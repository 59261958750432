import {useSelector} from "react-redux";
import {HANDLER_RESTAURANT_NAME} from "../../reducers/restaurantNameReducer";
import {HANDLER_RESTAURANT_EMAIL} from "../../reducers/restaurantEmailReducer";
import {HANDLER_TOTAL_ORDER_COST} from "../../reducers/totalOrderCostReducer";
import {HANDLER_ORDER_NUMBER} from "../../reducers/orderNumberReducer";
import {HANDLER_CUSTOMER_NAME} from "../../reducers/customerNameReducer";
import {HANDLER_CUSTOMER_LAST_NAME} from "../../reducers/customerLastNameReducer";
import {HANDLER_CUSTOMER_PHONE_NUMBER} from "../../reducers/customerPhoneNumberReducer";
import {HANDLER_CUSTOMER_EMAIL} from "../../reducers/customerEmailReducer";
import {HANDLER_CUSTOMER_ADDRESS} from "../../reducers/customerAddressReducer";
import {HANDLER_RESTAURANT_ADDRESS} from "../../reducers/restaurantAddressReducer";
import {HANDLER_RESTAURANT_PHONE_NUMBER} from "../../reducers/restaurantPhoneNumberReducer";
import {HANDLER_DELIVERY_FEE} from "../../reducers/deliveryFeeReducer";
import {HANDLER_TIPS} from "../../reducers/tipsReducer";
import {HANDLER_DELIVERY_INSTRUCTION} from "../../reducers/deliveryInstructionReducer";
import {HANDLER_EXPECTED_DELIVERY_DATE} from "../../reducers/expectedDeliveryDateReducer";
import {HANDLER_EXPECTED_DELIVERY_TIME} from "../../reducers/expectedDeliveryTimeReducer";
import {HANDLER_EXPECTED_PICKUP_TIME} from "../../reducers/expectedPickupTimeReducer";
import {HANDLER_DELIVERY_ADDRESS_TYPE} from "../../reducers/deliveryAddressTypeReducer";
import {HANDLER_PICKUP_ADDRESS_TYPE} from "../../reducers/pickupAddressTypeReducer";
import {HANDLER_RESTAURANT_CONTACT_NAME} from "../../reducers/restaurantContactNameReducer";
import {HANDLER_PICKUP_COMPANY_NAME} from "../../reducers/pickUpCompanyNameReducer";
import {HANDLER_RESTAURANT_LAST_NAME} from "../../reducers/restaurantLastNameReducer";
import {HANDLER_ITEM_SIZE} from "../../reducers/itemSizeReducer";
import {HANDLER_ITEM_TYPE} from "../../reducers/itemTypeReducer";
import {HANDLER_NUMBER_OF_ITEMS} from "../../reducers/numberOfItemsReducer";
import {HANDLER_SERVICE_TYPE} from "../../reducers/serviceTypeReducer";
import {convertTo12HourFormat, dateFormatFix, getCurrentDate, getCurrentTime} from "../../util/defaultTimeHelper";
import {HANDLER_EXPECTED_PICKUP_DATE} from "../../reducers/expectedPickUpDateReducer";
import {HANDLER_PAYMENT_METHOD} from "../../reducers/paymentMethodReducer";
import {HANDLER_ADDITIONAL_ADDRESS} from "../../reducers/additionalAddressInfo";
import {HANDLER_TAX} from "../../reducers/taxReducer";
import {HANDLER_BLOCK_NUMBER} from "../../reducers/customerBlockNumberReducer";
import {HANDLER_FLAT_NUMBER} from "../../reducers/customerFlatNumberReducer";
import {HANDLER_ROAD_NUMBER} from "../../reducers/customerRoadNumberReducer";
import {HANDLER_VILLA_NUMBER} from "../../reducers/customerVillaNumberReducer";
import {HANDLER_AREA_NAME} from "../../reducers/customerAreaNameReducer";
import { HANDLER_CUSTOMER_TAIL_NUMBER } from "../../reducers/customerTailNumberReducer";
import { HANDLER_NUMBER_OF_EXTRA_ITEMS } from "../../reducers/numberOfExtraItemsReducer";
import { HANDLER_NUMBER_OF_EXTRA_ITEMS_2 } from "../../reducers/numberOfExtraItems2Reducer";
import { HANDLER_PICKUP_INSTRUCTION } from "../../reducers/pickupInstructionReducer";


const useStateMapper = () => {
    const companyInfo = useSelector(state => state.companyDetails);
    const orderNumber = useSelector(state => state.orderNumber);
    const customerName = useSelector(state => state.customerName);
    const customerLastName = useSelector(state => state.customerLastName);
    const customerPhoneNumber = useSelector(state => state.customerPhoneNumber);
    const pickupCompanyName = useSelector(state => state.pickUpCompanyName);
    const customerEmail = useSelector(state => state.customerEmail);
    const customerAddress = useSelector(state => state.customerAddress);
    const restaurantName = useSelector(state => state.restaurantName);
    const restaurantLastName = useSelector(state => state.restaurantLastName);
    const restaurantContactName = useSelector(state => state.restaurantContactName);
    const restaurantEmail = useSelector(state => state.restaurantEmail);
    const restaurantAddress = useSelector(state => state.restaurantAddress);
    const restaurantPhoneNumber = useSelector(state => state.restaurantPhoneNumber);
    const deliveryFee = Number(useSelector(state => state.deliveryFee));
    const tips = Number(useSelector(state => state.tips));
    const totalDeliveryCost = Number(useSelector(state => state.totalOrderCost));
    const deliveryInstruction = useSelector(state => state.deliveryInstruction);
    const expectedDeliveryDate = useSelector(state => state.expectedDeliveryDate);
    const expectedDeliveryTime = useSelector(state => state.expectedDeliveryTime);
    const expectedPickupDate = useSelector(state => state.expectedPickUpDate);
    const expectedPickupTime = useSelector(state => state.expectedPickupTime);
    const deliveryAddressType = useSelector(state => state.deliveryAddressType);
    const pickupAddressType = useSelector(state => state.pickupAddressType);
    const itemSize = useSelector(state => state.itemSize);
    const itemType = useSelector(state => state.itemType);
    const numberOfItems = useSelector(state => state.numberOfItems);
    const serviceType = useSelector(state => state.serviceType);
    const deliveryLocation = useSelector(state => state.deliveryLocationInfo);
    const pickupLocation = useSelector(state => state.pickupLocationInfo);
    const paymentMethod = useSelector(state => state.paymentMethod);
    const additionalAddress = useSelector(state => state.additionalAddressInfo);
    const tax = useSelector(state => state.tax);
    const areaName = useSelector(state => state.customerAreaName);
    const blockNumber = useSelector(state => state.customerBlockNumber);
    const flatNumber = useSelector(state => state.customerFlatNumber);
    const roadNumber = useSelector(state => state.customerRoadNumber);
    const villaNumber = useSelector(state => state.customerVillaNumber);
    const customerTailNumber = useSelector(state => state.customerTailNumber);
    const numberOfExtraItems = useSelector(state => state.numberOfExtraItems);
    const numberOfExtraItems2 = useSelector(state => state.numberOfExtraItems2);
    const pickupInstruction = useSelector(state => state.pickupInstruction);

    let total = (totalDeliveryCost + deliveryFee + tips + tax);

    const updateLocationInfoInData = (data) => {
        if(pickupLocation != null) {
            data.pickupLatitude = pickupLocation.lat;
            data.pickupLongitude = pickupLocation.lng;
        }

        if(deliveryLocation != null) {
            data.deliveryLatitude = deliveryLocation.lat;
            data.deliveryLongitude = deliveryLocation.lng;
        }

        return data;
    }

    const stateMapper = (typeName) => {
        switch (typeName) {
            case HANDLER_ORDER_NUMBER:
                return orderNumber;
            case HANDLER_CUSTOMER_NAME:
                return customerName;
            case HANDLER_CUSTOMER_LAST_NAME:
                return customerLastName;
            case HANDLER_PICKUP_COMPANY_NAME:
                return pickupCompanyName;
            case HANDLER_CUSTOMER_PHONE_NUMBER:
                return customerPhoneNumber;
            case HANDLER_CUSTOMER_EMAIL:
                return customerEmail;
            case HANDLER_CUSTOMER_ADDRESS:
                return customerAddress;
            case HANDLER_RESTAURANT_NAME:
                return restaurantName;
            case HANDLER_RESTAURANT_LAST_NAME:
                return restaurantLastName;
            case HANDLER_RESTAURANT_CONTACT_NAME:
                return restaurantContactName;
            case HANDLER_RESTAURANT_EMAIL:
                return restaurantEmail;
            case HANDLER_RESTAURANT_ADDRESS:
                return restaurantAddress;
            case HANDLER_RESTAURANT_PHONE_NUMBER:
                return restaurantPhoneNumber;
            case HANDLER_DELIVERY_FEE:
                return deliveryFee;
            case HANDLER_TIPS:
                return tips;
            case HANDLER_TOTAL_ORDER_COST:
                return total.toFixed(2);
            case HANDLER_DELIVERY_INSTRUCTION:
                return deliveryInstruction;
            case HANDLER_EXPECTED_DELIVERY_DATE:
                return expectedDeliveryDate;
            case HANDLER_EXPECTED_DELIVERY_TIME:
                return expectedDeliveryTime;
            case HANDLER_EXPECTED_PICKUP_DATE:
                return expectedPickupDate;
            case HANDLER_EXPECTED_PICKUP_TIME:
                return expectedPickupTime;
            case HANDLER_DELIVERY_ADDRESS_TYPE:
                return deliveryAddressType;
            case HANDLER_PICKUP_ADDRESS_TYPE:
                return pickupAddressType;
            case HANDLER_ITEM_SIZE:
                return itemSize;
            case HANDLER_NUMBER_OF_ITEMS:
                return numberOfItems;
            case HANDLER_ITEM_TYPE:
                return itemType;
            case HANDLER_SERVICE_TYPE:
                return serviceType;
            case HANDLER_PAYMENT_METHOD:
                return paymentMethod;
            case HANDLER_ADDITIONAL_ADDRESS:
                return additionalAddress;
            case HANDLER_TAX:
                return tax;
            case HANDLER_AREA_NAME:
                return areaName;
            case HANDLER_BLOCK_NUMBER:
                return blockNumber;
            case HANDLER_FLAT_NUMBER:
                return flatNumber;
            case HANDLER_ROAD_NUMBER:
                return roadNumber;
            case HANDLER_VILLA_NUMBER:
                return villaNumber;
            case HANDLER_CUSTOMER_TAIL_NUMBER:
                return customerTailNumber;
            case HANDLER_NUMBER_OF_EXTRA_ITEMS:
                return numberOfExtraItems;
            case HANDLER_NUMBER_OF_EXTRA_ITEMS_2:
                return numberOfExtraItems2;
            case HANDLER_PICKUP_INSTRUCTION:
                return pickupInstruction;
            default:
                return "Unknown";
        }
    }

    const mailBodyCreator = (bodyGetterFunc, generatedOrderNumber, payload=null) => {

        const customerFullName = customerLastName ? customerName + " " + customerLastName : customerName;

        let data = {
            orderNumber: orderNumber || generatedOrderNumber,
            companyLogo: companyInfo.getCompanyLogo(),
            companyName: companyInfo.getCompanyName(),
            companyEmail: companyInfo.getEmail(),
            customerName: customerFullName || "N/A",
            customerAddress: customerAddress || "N/A",
            customerEmail: customerEmail || "N/A",
            customerPhoneNumber: customerPhoneNumber || "N/A",
            expectedDeliveryDate: dateFormatFix(expectedDeliveryDate) || "N/A",
            expectedDeliveryTime: convertTo12HourFormat(expectedDeliveryTime) || "N/A",
            expectedPickupDate: dateFormatFix(expectedPickupDate) || "N/A",
            expectedPickupTime: convertTo12HourFormat(expectedPickupTime)|| "N/A",
            restaurantName: restaurantName|| "N/A",
            restaurantAddress: restaurantAddress|| "N/A",
            restaurantPhoneNumber: restaurantPhoneNumber|| "N/A",
            restaurantEmail: restaurantEmail || "N/A",
            itemType: itemType|| "N/A",
            itemSize: itemSize|| "N/A",
            numberOfItems: numberOfItems|| "N/A",
            serviceType: serviceType|| "N/A",
            deliveryInstruction: payload?.["deliveryInstruction"] || deliveryInstruction || "N/A",
            orderCost: totalDeliveryCost|| "N/A",
            totalAmount: total.toFixed(2)|| "N/A",
            deliveryFee: deliveryFee|| "N/A",
            tips: tips|| "N/A",
            submitDate: getCurrentDate(),
            submitTime: getCurrentTime(),
            paymentMethod: paymentMethod,
            tax: tax
        }
        return bodyGetterFunc(data);
    }
    return [stateMapper, mailBodyCreator, updateLocationInfoInData];
}

export default useStateMapper;
