export const HANDLER_PICKUP_INSTRUCTION = "HANDLER_PICKUP_INSTRUCTION";

export default (state = null, action) => {
    switch (action.type) {
        case HANDLER_PICKUP_INSTRUCTION:
            return action.payload;
        default:
            return state;
    }
}


