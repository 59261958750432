import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    EMAIL_INPUT_FIELD,
    fieldJSONCreator, LAYOUT_DEFAULT, REMEMBER_SECTION, sectionTitleJSONCreator, SELECTOR_INPUT_FIELD, TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD, TIME_INPUT_FIELD, VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { OrderEmailTemplate4B } from "../../util/emailTemplates/orderEmailTemplate4B";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";
import { ADDITIONAL_INFO_DIV } from "../common/components/additionalInfo";
import { HANDLER_REMEMBER_INFO } from "../../reducers/rememberInfoReducer";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../reducers/expectedPickupTimeReducer";
import { HANDLER_TIPS } from "../../reducers/tipsReducer";
import { HANDLER_CUSTOMER_TAIL_NUMBER } from "../../reducers/customerTailNumberReducer";
import { formatTo24Hour } from "../../util/defaultTimeHelper";

// for eatumup411@gmail.com

const shops = [
    {
      name: "Taqueria Dos Hermanos",
      phone: "(559) 404-9560",
      address: "36629 Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Straw Hat Pizza",
      phone: "(559) 945-1234",
      address: "36618 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "New China Restaurant",
      phone: "(559) 945-2823",
      address: "36882 S Lassen, Huron, CA 93234"
    },
    {
      name: "La Esperanza",
      phone: "(559) 945-9496",
      address: "36865 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Moreno's Taqueria La Esquinita",
      phone: "(559) 945-9608",
      address: "36905 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Huron Wireless Smoke Shop",
      phone: "(925) 917-2565/(661) 996-6096",
      address: "36951 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Taco Tren",
      phone: "(559) 763-6004",
      address: "36461 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Taqueria El Rinconcito Diaz",
      phone: "(559) 763-6060",
      address: "36650 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Huron General Store",
      phone: "(559) 945-9620",
      address: "36456 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Family Dollar",
      phone: "(559) 418-0118",
      address: "36301 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Dollar General",
      phone: "(559) 418-5267",
      address: "36281 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Rhino Liquor Market",
      phone: "(559) 945-9484",
      address: "36629 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Fiesta Latino",
      phone: "(559) 309-0757",
      address: "36869 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Speedo Mart (Sinclair)",
      phone: "(559) 945-9125",
      address: "36509 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Sinclair Gas Station",
      phone: "(559) 945-9125",
      address: "36509 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Krispy Krunchy Chicken (Sinclair)",
      phone: "(559) 945-9125",
      address: "36509 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Los Amigos Food Center",
      phone: "(559) 945-9502",
      address: "36210 Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Huron Valley Food",
      phone: "(559) 945-9373",
      address: "36220 Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Lassen Market",
      phone: "(559) 612-3154",
      address: "36668 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Jet Stop",
      phone: "(559) 763-6000",
      address: "36010 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Chevron",
      phone: "(559) 945-1500",
      address: "36700 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Angel Meat Market Corp",
      phone: "(559) 945-2902",
      address: "36539 S Lassen Ave, Huron, CA 93234"
    },
    {
      name: "Jet Stop",
      phone: "(559) 763-6001",
      address: "17000 Myrtle Ave #A, Huron, CA 93234"
    }
  ]
  

const OrderForm35 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();



    const orderFormJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Full Name of Recipient", 'John Doe', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME, null, true),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Phone Number of Recipient", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER, null, true),
                fieldJSONCreator(EMAIL_INPUT_FIELD, "Email Address of Recipient", '', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL, null, true),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Street Address of Recipient", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS, null, true),
                fieldJSONCreator(REMEMBER_SECTION, "Remember delivery information", '', false, LAYOUT_DEFAULT, HANDLER_REMEMBER_INFO),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Please fill out the information for the service required:"),
            sectionFields: [
                fieldJSONCreator(ADDITIONAL_INFO_DIV, '', (<div style={{textAlign: "left", fontWeight: 600}}>Walmart Curbside Pickup - $20</div>)),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Walmert Pickup Address Location", t(FORM_LOCALE_KEYS.ENTER_LOCATION), false, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, false),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Walmart Curbside Pickup Time Slot", '', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_TAIL_NUMBER, [
                    {label: "7:00 AM - 8:00 AM", value: "7 : 00 AM"},
                    {label: "8:00 AM - 9:00 AM", value: "8 : 00 AM"},
                    {label: "9:00 AM - 10:00 AM", value: "9 : 00 AM"},
                    {label: "10:00 AM - 11:00 AM", value: "10 : 00 AM"},
                    {label: "11:00 AM - 12:00 PM", value: "11 : 00 AM"},
                ], false),
                fieldJSONCreator(ADDITIONAL_INFO_DIV, '', (<div style={{textAlign: "left", fontWeight: 600}}>Local Restaurant/Store Pickup - $8</div>)),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Which Local Restaurant/Store Did You Order From?", '', false, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, [
                                    {label: "--", value: "n/a"}, ...(shops.map(s => {return {label: s.name, value: s.name}}))
                                ], false),
                fieldJSONCreator(TIME_INPUT_FIELD, "Local Restaurant/Store Pickup Time?", '', false, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_TIME, null, false),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Tips", '', false, LAYOUT_DEFAULT, HANDLER_TIPS, null, false),
                fieldJSONCreator(TEXTAREA_FIELD, "Delivery Instructions", '', false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION, null, false),
                
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, []);

    const template = onlyDispatcherMail(OrderEmailTemplate4B)

    const preProcessFunc = (data) => {
        if (data.restaurantName === 'n/a') {
            data["expectedPickupTime"] = formatTo24Hour(data["customerTailNumber"]);
        }
        delete data["customerTailNumber"];
        return data;
    }

    const postProcessFunc = (data) => {
        if (data.restaurantName === 'n/a') {
            data.restaurantName = "Walmert";
            data.restaurantPhoneNumber = "xxxxx";
        } else {
            const shop = shops.find(s => s.name === data.restaurantName);
            data.restaurantPhoneNumber = shop.phone;
            data.restaurantAddress = shop.address;
            delete data["pickupLatitude"];
            delete data["pickupLongitude"];
        }
        return data;
    }

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderFormJson} emailTemplateFunc={template} preProcessFunc={preProcessFunc} postProcessFunc={postProcessFunc}/>
        </>
    )
}

export default OrderForm35;
