import React, { useEffect } from "react";
import "../../../stylesheets/paidForm.css";
import { findCompanyDetails } from "../../../actions/companyDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaidFormLogoSection from "../../common/paidComponents/paidFormLogoSection";
import { HANDLER_RESTAURANT_NAME } from "../../../reducers/restaurantNameReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_NAME } from "../../../reducers/customerNameReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../../reducers/customerPhoneNumberReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../../reducers/customerAddressReducer";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../../reducers/deliveryInstructionReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../../reducers/customerEmailReducer";
import i18next from "i18next";
import { useStripePaidForm } from "../../customHooks/stripePaidFormHook";
import StripeNotConnected from "../../common/paidComponents/stripeNotConnected";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import { EMAIL_INPUT_FIELD, REMEMBER_SECTION, SELECTOR_INPUT_FIELD, TEXT_INPUT_FIELD, TEXTAREA_ADDRESS_FIELD, TEXTAREA_FIELD } from "../../../util/formBuilderHelper";
import GenericOrderDetailsInputBuilder from "../../common/paidFormBuilders/genericOrderDetailsInputBuilder";
import GenericOrderPaymentPageBuilder from "../../common/paidFormBuilders/genericOrderPaymentPageBuilder";
import { CustomOrderDetails } from "./customOrderDetails";
import { HANDLER_ITEM_TYPE } from "../../../reducers/itemTypeReducer";
import { HANDLER_CUSTOMER_LAST_NAME } from "../../../reducers/customerLastNameReducer";

// for pavanshetty77@gmail.com

const CustomPaidForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();

    const companyInfo = useSelector(state => state.companyDetails);
    const [stripeDetails, isEstimating, estimationComplete, estimate, resetEstimation] = useStripePaidForm(companyInfo);

    const showPayment = estimationComplete;
    const showSpinner = isEstimating;

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, [params.id])

    useEffect(() => {
        if (companyInfo !== null)
            i18next.changeLanguage(companyInfo.getLanguage());
    }, [companyInfo?.getLanguage()]);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        estimate();
    }

    const handleBackToEdit = (ev) => {
        ev.preventDefault();
        resetEstimation();
    }


    if (stripeDetails === null || companyInfo === null) return <></>;

    const config = [
        {
            sectionHeader: {title: "Customer Details"},
            sectionFields: [
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Full Name",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_NAME,
                },
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Company Name",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_LAST_NAME,
                },
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Phone Number",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                },
                {
                    fieldType: EMAIL_INPUT_FIELD,
                    placeholder: "Email Address",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_EMAIL,
                },
                {
                    fieldType: TEXTAREA_ADDRESS_FIELD,
                    placeholder: "Street Address",
                    required: true,
                    handlerType: HANDLER_CUSTOMER_ADDRESS,
                },
            ]
        },
        {
            sectionHeader: {title: "Pick-up from"},
            sectionFields: [
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Full name of pickup point/person",
                    required: true,
                    handlerType: HANDLER_RESTAURANT_NAME,
                },
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Phone number of pickup point/person",
                    required: true,
                    handlerType: HANDLER_RESTAURANT_PHONE_NUMBER,
                },
                {
                    fieldType: TEXTAREA_ADDRESS_FIELD,
                    placeholder: "Street address of pickup point/person",
                    required: true,
                    handlerType: HANDLER_RESTAURANT_ADDRESS,
                }
            ]
        },
        {
            sectionHeader: {title: "Delivery Details"},
            sectionFields: [
                {
                    fieldType: SELECTOR_INPUT_FIELD,
                    placeholder: "Package Contents",
                    required: true,
                    handlerType: HANDLER_ITEM_TYPE,
                    selectionOptions: [
                        {label: "Select Package Contents", value: "", disabled: true},
                        {label: "Documents", value: "Documents"},
                        {label: "Electronic Items", value: "Electronic Items"},
                        {label: "Parcel", value: "Parcel"},
                        {label: "Other Items (Fit in a Car)", value: "Other Items (Fit in a Car)"},
                    ]
                },
                {
                    fieldType: TEXTAREA_FIELD,
                    placeholder: "Delivery Instructions (Optional)",
                    required: false,
                    handlerType: HANDLER_DELIVERY_INSTRUCTION,
                },
            ]
        },
        {
            sectionHeader: {title: "Terms and Conditions"},
            sectionFields: [
                {
                    fieldType: REMEMBER_SECTION,
                    label: "I authorize the company to pick-up the items or order on my behalf and deliver them",
                    required: true,
                    handlerType: "NONE",
                },
                {
                    fieldType: REMEMBER_SECTION,
                    label: "I agree to pay the company as per the Terms of Conditions",
                    required: true,
                    handlerType: "NONE",
                },
                {
                    fieldType: REMEMBER_SECTION,
                    label: "By confirming I accept the order doesn't contain illegal/restricted items. Kwik Delivery partners may ask to verify the contents of the package and would chose to refuse the task if the items aren't verified Terms and conditions",
                    required: true,
                    handlerType: "NONE",
                },
            ]
        },
    ]

    const preProcessFunc = (data) => {
        data["customerName"] += " (" + data["customerLastName"] + ")";
        delete data["customerLastName"];
        return data;
    }

    if(!stripeDetails.isConnected()) return (
        <StripeNotConnected>
            <GenericOrderDetailsInputBuilder config={config} visible={!showPayment} loading={showSpinner}/>
        </StripeNotConnected>
    );

    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={showPayment} backToEdit={handleBackToEdit}/>
            <div className="container-md">
                <form className={`col-lg-6 offset-lg-3 pb-5 ${showPayment ? "d-none" : ""}`} onSubmit={handleSubmit}>
                    <GenericOrderDetailsInputBuilder config={config} visible={!showPayment} loading={showSpinner}/>
                </form>
            </div>
            <div id="paymentSection" className={`container-md ${showPayment ? "" : "d-none"}`}>
                <div className="col-lg-6 offset-lg-3">
                    <GenericOrderPaymentPageBuilder config={config} visible={showPayment} preProcessFunc={preProcessFunc}
                        details={{orderComponent: <CustomOrderDetails />}}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomPaidForm;
