import {BrowserRouter, Route, Routes} from 'react-router-dom';
import SimpleForm from "./components/forms/simpleForm";
import "./stylesheets/common.css";
import "./stylesheets/addressPlugin/address.plugin.css";
import OrderFormShort from "./components/forms/orderFormShort";
import OrderForm2 from "./components/forms/orderForm2";
import OrderFormSpecial from "./components/forms/orderFormSpecial";
import RestaurantOrderForm2 from "./components/forms/restaurantOrderForm2";
import RestaurantOrderForm from "./components/forms/restaurantOrderForm";
import RestaurantShortOrderFormA from "./components/forms/restaurantShortOrderFormA";
import OrderForm3 from "./components/forms/orderForm3";
import OrderForm4 from "./components/forms/orderForm4";
import OrderForm4A from "./components/forms/orderForm4A";
import OrderForm4B from "./components/forms/orderForm4B";
import OrderForm from "./components/forms/orderForm";
import PaymentSuccessWrapper from "./components/paymentReturnPages/paymentSuccessWrapper";
import RestaurantOrderForm3 from "./components/customForms/restaurantOrderForm3";
import RestaurantOrderForm6 from "./components/customForms/restaurantOrderForm6";
import RestaurantOrderForm7 from "./components/customForms/restaurantOrderForm7";
import OrderFormSpecial2 from "./components/customForms/orderFormSpecial2";
import OrderForm5 from "./components/customForms/orderForm5";
import OrderForm6 from "./components/customForms/orderForm6";
import OrderForm7 from "./components/customForms/orderForm7";
import CourierForm4 from "./components/customForms/courierForm4";
import SimpleFormWithoutPayment from "./components/paidForms/simpleFormWithoutPayment";
import SimpleFormWithoutPayment2 from "./components/paidCustomForm/simpleFormWithoutPayment2/simpleFormWithoutPayment";
import OrderForm8 from "./components/customForms/orderForm8";
import CourierForm5 from "./components/customForms/courierForm5";
import CourierForm6 from "./components/customForms/courierForm6";
import OrderForm9 from "./components/customForms/orderForm9";
import CourierForm7 from "./components/customForms/courierForm7";
import RestaurantOrderForm4 from "./components/customForms/restaurantOrderForm4";
import OrderFormShort2 from "./components/customForms/orderFormShort2";
import CourierForm8 from "./components/customForms/courierForm8";
import OrderFormSpecial3 from "./components/customForms/orderFormSpecial3";
import SimpleFormWithoutPaymentGreek from "./components/paidCustomForm/simpleFormWithoutPaymentGreek";
import SimplePaidForm from "./components/paidForms/simplePaidForm";
import CustomPaidForm from "./components/paidCustomForm/paidCustomForm2/customPaidFrom";
import CustomPaidForm2 from "./components/paidCustomForm/paidCustomForm3/customPaidFrom2";
import CustomPaidOrderForm4 from "./components/paidCustomForm/paidCustomForm4/customPaidOrderForm4";
import CustomPaidForm5 from "./components/paidCustomForm/paidCustomForm5/customPaidFrom5";
import OrderForm10 from "./components/customForms/orderForm10";
import CustomPaidFormWithoutPayment from "./components/paidCustomForm/paidCustomForm6/customPaidFormWithoutPayment";
import CustomPaidForm7 from "./components/paidCustomForm/paidCustomForm7/customPaidFrom7";
import CustomPaidOrderForm8 from "./components/paidCustomForm/paidCustomForm8/customPaidOrderForm8";
import CustomPaidFrom18 from "./components/paidCustomForm/paidCustomForm18/customPaidFrom18";
import CustomPaidOrderForm9 from "./components/paidCustomForm/paidCustomForm9/customPaidOrderForm8";
import RestaurantOrderForm5 from "./components/customForms/restaurantOrderForm5";
import CustomPaidOrderForm10 from "./components/paidCustomForm/paidCustomForm10/customPaidOrderForm10";
import CustomPaidForm11 from "./components/paidCustomForm/paidCustomForm11/customPaidFrom11";
import CourierForm11 from "./components/customForms/customForm11";
import CourierForm12 from "./components/customForms/courierForm12";
import CustomPaidForm12 from "./components/paidCustomForm/paidCustomForm12/customPaidFrom11";
import CustomPaidForm13 from "./components/paidCustomForm/paidCustomForm13/customPaidFrom13";
import CustomPaidForm19 from "./components/paidCustomForm/paidCustomForm19/customPaidFrom19";
import CustomPaidFrom20 from "./components/paidCustomForm/paidCustomForm20/customPaidFrom20";
import CustomPaidForm21 from "./components/paidCustomForm/paidCustomForm21/customPaidFrom21";
import CustomFormWithoutPayment13 from "./components/paidCustomForm/paymentCalculationForm13/simpleFormWithoutPayment";
import CustomFormWithoutPayment14 from "./components/paidCustomForm/paymentCalculationForm14/simpleFormWithoutPayment";
import CustomFormWithoutPaymentWithCard15 from "./components/paidCustomForm/paymentCalculationForm15/CustomFormWithoutPayment";
import CustomFormWithoutPaymentWithCard16 from "./components/paidCustomForm/paymentCalculationForm16/CustomFormWithoutPayment";
import CourierForm13 from "./components/customForms/courierForm13";
import CourierForm14 from "./components/customForms/courierForm14";
import CourierForm15 from "./components/customForms/courierForm15";
import CourierForm16 from "./components/customForms/courierForm16";
import OrderForm17 from "./components/customForms/orderForm17";
import CustomPaidForm22 from "./components/paidCustomForm/paidCustomForm22/customPaidForm";
import OrderForm18 from "./components/customForms/orderForm18";
import OrderForm19 from "./components/customForms/orderForm19";
import OrderForm20 from "./components/customForms/orderForm20";
import OrderForm21 from "./components/customForms/orderForm21";
import OrderForm33 from "./components/customForms/orderForm33";
import CustomPaidForm23 from "./components/paidCustomForm/paidCustomForm23/customPaidForm";
import OrderForm23 from "./components/customForms/orderForm23";
import OrderForm24 from "./components/customForms/orderForm24"
import OrderForm25 from "./components/customForms/orderForm25"
import OrderForm26 from "./components/customForms/orderForm26"
import OrderForm27 from "./components/customForms/orderForm27"
import OrderForm28 from "./components/customForms/orderForm28"
import OrderForm29 from "./components/customForms/orderForm29"
import OrderForm30 from './components/customForms/orderForm30';
import OrderForm31 from './components/customForms/orderForm31';
import OrderForm32 from './components/customForms/orderForm32';
import OrderForm34 from './components/customForms/orderForm34';
import OrderForm35 from './components/customForms/orderForm35';
import CustomPaidForm24 from "./components/paidCustomForm/paidCustomForm24/customPaidForm";
import OrderForm36 from './components/customForms/orderForm36';
import CustomPaidForm25 from "./components/paidCustomForm/paidCustomForm25/customPaidForm";
import CustomPaidForm26 from "./components/paidCustomForm/paidCustomForm26/customPaidForm";
import CustomPaidForm27 from "./components/paidCustomForm/paidCustomForm27/customPaidForm";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/simpleForm/:id" element={<SimpleForm/>}/>
                <Route path="/orderFormShort/:id" element={<OrderFormShort/>}/>
                <Route path="/orderForm/:id" element={<OrderForm/>}/>
                <Route path="/orderForm2/:id" element={<OrderForm2/>}/>
                <Route path="/orderForm3/:id" element={<OrderForm3/>}/>
                <Route path="/orderForm4/:id" element={<OrderForm4/>}/>
                <Route path="/orderForm4A/:id" element={<OrderForm4A/>}/>
                <Route path="/orderForm4B/:id" element={<OrderForm4B/>}/>
                <Route path="/orderFormSpecial/:id" element={<OrderFormSpecial/>}/>
                <Route path="/RestaurantOrderForm/:id" element={<RestaurantOrderForm/>}/>
                <Route path="/RestaurantOrderForm2/:id" element={<RestaurantOrderForm2/>}/>
                <Route path="/restaurantShortOrderFormA/:id" element={<RestaurantShortOrderFormA/>}/>
                <Route path="/custom/RestaurantOrderForm3/:id" element={<RestaurantOrderForm3/>}/>
                <Route path="/custom/orderFormSpecial2/:id" element={<OrderFormSpecial2/>}/>
                <Route path="/custom/orderFormSpecial3/:id" element={<OrderFormSpecial3/>}/>
                <Route path="/custom/courierForm4/:id" element={<CourierForm4/>}/>
                <Route path="/custom/courierForm5/:id" element={<CourierForm5/>}/>
                <Route path="/custom/courierForm6/:id" element={<CourierForm6/>}/>
                <Route path="/custom/courierForm7/:id" element={<CourierForm7/>}/>
                <Route path="/custom/courierForm8/:id" element={<CourierForm8/>}/>
                <Route path="/custom/orderForm5/:id" element={<OrderForm5/>}/>
                <Route path="/custom/orderForm6/:id" element={<OrderForm6/>}/>
                <Route path="/custom/orderForm7/:id" element={<OrderForm7/>}/>
                <Route path="/custom/orderForm8/:id" element={<OrderForm8/>}/>
                <Route path="/custom/orderForm9/:id" element={<OrderForm9/>}/>
                <Route path="/custom/orderForm10/:id" element={<OrderForm10/>}/>
                <Route path="/custom/orderForm10/:id" element={<OrderForm10/>}/>
                <Route path="/custom/orderForm11/:id" element={<CourierForm11/>}/>
                <Route path="/custom/orderForm12/:id" element={<CourierForm12/>}/>
                <Route path="/custom/orderForm13/:id" element={<CourierForm13/>}/>
                <Route path="/custom/orderForm14/:id" element={<CourierForm14/>}/>
                <Route path="/custom/orderForm15/:id" element={<CourierForm15/>}/>
                <Route path="/custom/orderForm16/:id" element={<CourierForm16/>}/>
                <Route path="/custom/orderForm17/:id" element={<OrderForm17/>}/>
                <Route path="/custom/orderForm18/:id" element={<OrderForm18/>}/>
                <Route path="/custom/orderForm19/:id" element={<OrderForm19/>}/>
                <Route path="/custom/orderForm20/:id" element={<OrderForm20/>}/>
                <Route path="/custom/orderForm21/:id" element={<OrderForm21/>}/>
                {/* <Route path="/custom/orderForm22/:id" element={<OrderForm22/>}/> */} 
                <Route path="/custom/orderForm23/:id" element={<OrderForm23/>}/>
                <Route path="/custom/orderForm24/:id" element={<OrderForm24/>}/>
                <Route path="/custom/orderForm25/:id" element={<OrderForm25/>}/>
                <Route path="/custom/orderForm26/:id" element={<OrderForm26/>}/>
                <Route path="/custom/orderForm27/:id" element={<OrderForm27/>}/>
                <Route path="/custom/orderForm28/:id" element={<OrderForm28/>}/>
                <Route path="/custom/orderForm29/:id" element={<OrderForm29/>}/>
                <Route path="/custom/orderForm30/:id" element={<OrderForm30/>}/>
                <Route path="/custom/orderForm31/:id" element={<OrderForm31/>}/>
                <Route path="/custom/orderForm32/:id" element={<OrderForm32/>}/>
                <Route path="/custom/orderForm33/:id" element={<OrderForm33/>}/>
                <Route path="/custom/orderForm34/:id" element={<OrderForm34/>}/>
                <Route path="/custom/orderForm35/:id" element={<OrderForm35/>}/>
                <Route path="/custom/orderForm36/:id" element={<OrderForm36/>}/>
                <Route path="/custom/orderFormShort2/:id" element={<OrderFormShort2/>}/>
                <Route path="/custom/RestaurantOrderForm4/:id" element={<RestaurantOrderForm4/>}/>
                <Route path="/custom/RestaurantOrderForm5/:id" element={<RestaurantOrderForm5/>}/>
                <Route path="/custom/RestaurantOrderForm6/:id" element={<RestaurantOrderForm6/>}/>
                <Route path="/custom/RestaurantOrderForm7/:id" element={<RestaurantOrderForm7/>}/>
                <Route path="/order/:id" element = {<SimplePaidForm/>}/>
                <Route path="/custom/order/:id" element = {<CustomPaidForm/>}/>
                <Route path="/custom/order2/:id" element = {<CustomPaidForm2/>}/>
                <Route path="/custom/order3/:id" element = {<CustomPaidOrderForm4/>}/>
                <Route path="/custom/order4/:id" element = {<CustomPaidForm5/>}/>
                <Route path="/custom/order5/:id" element = {<CustomPaidForm7/>}/>
                <Route path="/custom/order6/:id" element = {<CustomPaidOrderForm8/>}/>
                <Route path="/custom/order11/:id" element = {<CustomPaidForm11/>}/>
                <Route path="/custom/order12/:id" element = {<CustomPaidForm12/>}/>
                <Route path="/custom/order13/:id" element = {<CustomPaidForm13/>}/>
                <Route path="/custom/order14/:id" element = {<CustomPaidFrom18/>}/>
                <Route path="/custom/order15/:id" element = {<CustomPaidForm19/>}/>
                <Route path="/custom/order16/:id" element = {<CustomPaidFrom20/>}/>
                <Route path="/custom/order17/:id" element = {<CustomPaidForm21/>}/>
                <Route path="/custom/order18/:id" element = {<CustomPaidForm22/>}/>
                <Route path="/custom/order19/:id" element = {<CustomPaidForm23/>}/>
                <Route path="/custom/order20/:id" element = {<CustomPaidForm24/>}/>
                <Route path="/custom/order21/:id" element = {<CustomPaidForm25/>}/>
                <Route path="/custom/order22/:id" element = {<CustomPaidForm26/>}/>
                <Route path="/custom/order23/:id" element = {<CustomPaidForm27/>}/>
                <Route path="/custom/paymentCalcForm/:id" element = {<CustomPaidOrderForm9/>}/>
                <Route path="/custom/withoutUpfrontPayOrder5/:id" element = {<CustomPaidFormWithoutPayment/>}/>
                <Route path="/custom/withoutUpfrontPayOrder10/:id" element = {<CustomPaidOrderForm10/>}/>
                <Route path="/custom/withoutUpfrontPayOrder13/:id" element = {<CustomFormWithoutPayment13/>}/>
                <Route path="/custom/withoutUpfrontPayOrder14/:id" element = {<CustomFormWithoutPayment14/>}/>
                <Route path="/custom/withoutUpfrontPayOrder15/:id" element = {<CustomFormWithoutPaymentWithCard15/>}/>
                <Route path="/custom/withoutUpfrontPayOrder16/:id" element = {<CustomFormWithoutPaymentWithCard16/>}/>
                <Route path="/custom/withoutUpfrontPayOrder17/:id" element = {<SimpleFormWithoutPayment2/>}/>
                <Route path="/orderWithoutUpfrontPay/:id" element = {<SimpleFormWithoutPayment/>}/>
                <Route path="/orderWithoutUpfrontPayGreek/:id" element = {<SimpleFormWithoutPaymentGreek/>}/>
                <Route path="/paymentSuccess/:orderId" element = {<PaymentSuccessWrapper/>}/>
                <Route path="*" element={<SimpleForm/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
