const ONE_HOUR = 60 * 60 * 1000;

export const defaultPickUpTime = () => {
    let currDate = new Date(Date.now() + 1800000);
    return {
        time: twoDigit(currDate.getHours()) + ":" + twoDigit(currDate.getMinutes()) + ":" + twoDigit(currDate.getSeconds()),
        date: twoDigit(currDate.getDate())+"/"+twoDigit(currDate.getMonth() + 1)+"/"+currDate.getFullYear()
    }
}

export const getTimeWithDelay = (delayInMs) => {
    let pickupDateTime = new Date(Date.now() + delayInMs);
    return {
        time: twoDigit(pickupDateTime.getHours()) + ":" + twoDigit(pickupDateTime.getMinutes()) + ":" + twoDigit(pickupDateTime.getSeconds()),
        date: twoDigit(pickupDateTime.getDate())+"/"+twoDigit(pickupDateTime.getMonth() + 1)+"/"+pickupDateTime.getFullYear()
    }
}

export function addMinutesToTime(timeStr, minutesToAdd) {
    let timeComponents = timeStr.split(":");
    let hours = parseInt(timeComponents[0]);
    let minutes = parseInt(timeComponents[1].split(" ")[0]);
    let amPm = timeComponents[1].split(" ")[1];

    if (amPm === "AM" && hours === 12) {
        hours = 0;
    } else if (amPm === "PM" && hours !== 12) {
        hours += 12;
    }

    let dateObj = new Date();
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    dateObj.setMinutes(dateObj.getMinutes() + minutesToAdd);

    let formattedDate = dateObj.toISOString().split("T")[0];
    let formattedTime = dateObj.toTimeString().split(" ")[0];

    return {
        date: formattedDate,
        time: formattedTime
    };
}

export const defaultDeliveryTime = (pickupDate = null, pickupTime = null) => {
    let expectedDate = null;

    const isValid = (val) => {
        return val !== null && val !== undefined && val !== "";
    }

    if ( ! isValid(pickupTime) && ! isValid(pickupDate)) {
        expectedDate = new Date(Date.now() + ONE_HOUR);
    } else {
        try {
            const tempCurDate = new Date();

            if (isValid(pickupDate)) {
                let day, month, year;
                if (pickupDate.includes("-")) {
                    [day, month, year] = pickupDate.split('-');
                } else [day, month, year] = pickupDate.split('/');
                expectedDate = new Date(year, month - 1, day);
            } else {
                expectedDate = new Date(tempCurDate.getFullYear(), tempCurDate.getMonth(), tempCurDate.getDate());
            }

            if (isValid(pickupTime)) {
                const [hours, minutes] = pickupTime.split(':');
                expectedDate.setHours(parseInt(hours));
                expectedDate.setMinutes(parseInt(minutes));
            }

            expectedDate.setTime(expectedDate.getTime() + ONE_HOUR);

            if (isNaN(expectedDate.getTime())) {
                expectedDate = new Date(Date.now() + ONE_HOUR);
            }
        } catch (e) {
            console.error(e);
            expectedDate = new Date(Date.now() + ONE_HOUR);
        }
    }

    return {
        time: twoDigit(expectedDate.getHours()) + ":" + twoDigit(expectedDate.getMinutes()) + ":" + twoDigit(expectedDate.getSeconds()),
        date: twoDigit(expectedDate.getDate()) + "/" + twoDigit(expectedDate.getMonth() + 1) + "/" + expectedDate.getFullYear()
    }
}

export const twoDigit = (val) => {
    if(val < 10) return "0" + Number(val);
    return val;
}

export const dateFormatFix = (dateStr) => {
    let dateTemp = dateStr.split("/");
    if (dateTemp[0].length === 4) {
        return dateTemp[2] + "/" + dateTemp[1] + "/" + dateTemp[0];
    }
    return dateStr;
}

export const dateFormatYYYYFirst = (dateStr) => {
    if(dateStr === "" || dateStr === null) return;
    let dateTemp = dateStr.split("/");
    if(dateTemp.length === 1) dateTemp = dateStr.split("-");
    if (dateTemp[0].length === 2) {
        return twoDigit(dateTemp[2]) + "-" + twoDigit(dateTemp[1]) + "-" + dateTemp[0];
    }
    return dateStr;
}

export const convertTo12HourFormat = (time) => {
    let timeArr = time.split(":");
    let hour = timeArr[0] % 12 || 12;
    let amPM = (timeArr[0] < 12 || timeArr[0] == 24) ? "AM" : "PM";
    return twoDigit(hour) + " : " + twoDigit(timeArr[1]) + " " + amPM
}

// "hh:mm:ss" => "hh : mm"
export const convertTo24HourFormat = (jsTimeStr) => {
    let timeArr = jsTimeStr.split(":");
    return twoDigit(timeArr[0]) + " : " + twoDigit(timeArr[1]);
}

// "hh : mm XM" => boolean
export const check12HourTime = (timestr) => {
    let [hours, minutesAndMeridiem] = timestr.split(":");
    if(!minutesAndMeridiem) return false;
    
    let [minutes, meridiem] = minutesAndMeridiem.trim().split(" ")
    if(!meridiem) return false;

    hours = hours.trim();
    minutes = minutes.trim();
    meridiem = meridiem.trim();

    if(isNaN(Number(hours)) || isNaN(Number(minutes))) return false;
    if(hours <= 0 || 12 < hours) return false;
    if(minutes < 0 || 59 < minutes) return false;
    if(meridiem != "AM" && meridiem != "PM") return false;
    return true;
}

// "hh : mm" => boolean
export const check24HourTime = (wickedTimestr) => {
    let [hours, minutes] = wickedTimestr.split(":");
    if(!minutes) return false;

    hours = hours.trim();
    minutes = minutes.trim();

    if (hours === "" || minutes === "") return false;
    if(isNaN(Number(hours)) || isNaN(Number(minutes))) return false;
    if(hours < 0 || 23 < hours) return false;
    if(minutes < 0 || 59 < minutes) return false;
    return true;
}

export const formatTo24Hour = (timeStr) => {
    try {
        if (timeStr === null || timeStr.match(/^(\d+)/) === null) return "";
        let hours = Number(timeStr.match(/^(\d+)/)[1]);
        let minutes = Number(timeStr.match(/: (\d+)/)[1]);
        let AMPM = timeStr.match(/[a-zA-Z]+/)[0];
        if (AMPM === "PM" && hours < 12) hours = hours + 12;
        if (AMPM === "AM" && hours === 12) hours = hours - 12;
        let sHours = hours.toString();
        let sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return (sHours + ":" + sMinutes + ":00");
    } catch (e) {
        console.log(e);
        return "";
    }
}

// "hh : mm" => "hh:mm:ss"
export const formatWicked24hToJs24h = (wickedTimeStr) => {
    try {
        let [hours, minutes] = wickedTimeStr.split(":");
        hours = hours.trim();
        minutes = minutes.trim();
        return twoDigit(hours) + ":" + twoDigit(minutes) + ":00";
    } catch (e) {
        console.log(e);
        return "";
    }
}

export const dateTimeFormatterToUTC = (date, time) => {
    let tempDateTime = dateFormatYYYYFirst(date) + "T" + time + "Z";
    let date1 = new Date(tempDateTime);

    if(isNaN(date1.getTime())){
        tempDateTime = tempDateTime.replaceAll("-","/");
        date1 = new Date(tempDateTime);
    }

    let date2 = new Date(
        date1.getTime() +
        (new Date().getTimezoneOffset() * 60000)
    );
    //console.log(tempDateTime+' '+date2);
    let utcTime = date2.toUTCString();
    return {time: utcTime.substring(17, 25), date: convertToSqlDateFormat(utcTime.substring(0, 16))}
}

export const convertToSqlDateFormat = function (tempDate) {
    //input format : Tue, 11 Aug 2015
    let date = tempDate.substring(5, 7);
    let monthInWord = tempDate.substring(8, 11);
    let year = tempDate.substring(12);
    let monthInNumber = getMonthNumber(monthInWord);
    let month
    if (monthInNumber < 10) {
        month = '0' + monthInNumber;
    } else {
        month = monthInNumber;
    }
    let formattedDate = year + '-' + month + '-' + date;
    return formattedDate;
};

export const getMonthNumber = function (month) {
    switch (month) {
        case 'Jan':
            return 1;
        case 'Feb':
            return 2;
        case 'Mar':
            return 3;
        case 'Apr':
            return 4;
        case 'May':
            return 5;
        case 'Jun':
            return 6;
        case 'Jul':
            return 7;
        case 'Aug':
            return 8;
        case 'Sep':
            return 9;
        case 'Oct':
            return 10;
        case 'Nov':
            return 11;
        case 'Dec':
            return 12;
    }
}

export const dateTimeFormattedAsString = (date, time) => {
    let str = "";
    let dateTemp = new Date(dateFormatYYYYFirst(date));
    let currDate = new Date();
    if (date === undefined || date === null) str += "Today";
    else if(dateTemp.getDate() === currDate.getDate() && dateTemp.getMonth() === currDate.getMonth() && dateTemp.getFullYear() === currDate.getFullYear()) str += "Today";
    else str += date;
    str += " at ";
    time = convertTo12HourFormat(time);
    let timeArr = time.split(" ");
    str+= timeArr[0] + timeArr[1] + timeArr[2] + " " + timeArr[3];
    return str;
}

export const getCurrentDate = () => {
    let currDateObj = new Date();
    return twoDigit(currDateObj.getDate()) + "/" + twoDigit(currDateObj.getMonth() + 1) + "/" + currDateObj.getFullYear();
}

export const getCurrentTime = () => {
    let currDateObj = new Date();
    return convertTo12HourFormat(currDateObj.getHours() + ":" + currDateObj.getMinutes() + ":" + currDateObj.getSeconds());
}

export function convertTo12Hour(time24) {
    let [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    const time12 = `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
    return time12;
}