import {combineReducers} from "redux";
import orderNumberReducer from "./orderNumberReducer";
import companyDetailsReducer from "./companyDetailsReducer";
import customerNameReducer from "./customerNameReducer";
import customerLastNameReducer from "./customerLastNameReducer";
import customerPhoneNumberReducer from "./customerPhoneNumberReducer";
import customerEmailReducer from "./customerEmailReducer";
import customerAddressReducer from "./customerAddressReducer";
import restaurantNameReducer from "./restaurantNameReducer";
import restaurantContactNameReducer from "./restaurantContactNameReducer";
import restaurantAddressReducer from "./restaurantAddressReducer";
import restaurantEmailReducer from "./restaurantEmailReducer";
import restaurantPhoneNumberReducer from "./restaurantPhoneNumberReducer";
import deliveryFeeReducer from "./deliveryFeeReducer";
import tipsReducer from "./tipsReducer";
import totalOrderCostReducer from "./totalOrderCostReducer";
import deliveryInstructionReducer from "./deliveryInstructionReducer";
import expectedDeliveryDateReducer from "./expectedDeliveryDateReducer";
import expectedDeliveryTimeReducer from "./expectedDeliveryTimeReducer";
import expectedPickupTimeReducer from "./expectedPickupTimeReducer";
import expectedPickUpDateReducer from "./expectedPickUpDateReducer";
import deliveryAddressTypeReducer from "./deliveryAddressTypeReducer";
import pickupAddressTypeReducer from "./pickupAddressTypeReducer";
import orderItemsReducer from "./orderItemsReducer";
import orderSubmitStateReducer from "./orderSubmitStateReducer";
import pickUpCompanyNameReducer from "./pickUpCompanyNameReducer";
import restaurantLastNameReducer from "./restaurantLastNameReducer";
import itemTypeReducer from "./itemTypeReducer";
import itemSizeReducer from "./itemSizeReducer";
import numberOfItemsReducer from "./numberOfItemsReducer";
import serviceTypeReducer from "./serviceTypeReducer";
import rememberInfoReducer from "./rememberInfoReducer";
import clientSecretReducer from "./clientSecretReducer";
import stripeDetailsReducer from "./stripeDetailsReducer";
import estimatedInfoReducer from "./estimatedInfoReducer";
import pickUpLocationReducer from "./pickUpLocationReducer";
import deliveryLocationReducer from "./deliveryLocationReducer";
import paymentMethodReducer from "./paymentMethodReducer";
import additionalAddressInfoReducer from "./additionalAddressInfo";
import taxReducer from "./taxReducer";
import customerAreaNameReducer from "./customerAreaNameReducer";
import customerBlockNumberReducer from "./customerBlockNumberReducer";
import customerFlatNumberReducer from "./customerFlatNumberReducer";
import customerRoadNumberReducer from "./customerRoadNumberReducer";
import customerVillaNumberReducer from "./customerVillaNumberReducer";
import customerTailNumberReducer from "./customerTailNumberReducer";
import numberOfExtraItemsReducer from "./numberOfExtraItemsReducer";
import paidFormErrorReducer from "./paidFormErrorReducer";
import numberOfExtraItems2Reducer from "./numberOfExtraItems2Reducer";
import pickupInstructionReducer from "./pickupInstructionReducer";

export default combineReducers({
    orderNumber: orderNumberReducer,
    companyDetails: companyDetailsReducer,
    rememberInfo: rememberInfoReducer,
    customerName: customerNameReducer,
    customerLastName: customerLastNameReducer,
    pickUpCompanyName: pickUpCompanyNameReducer,
    customerPhoneNumber: customerPhoneNumberReducer,
    customerEmail: customerEmailReducer,
    customerAddress: customerAddressReducer,
    restaurantName: restaurantNameReducer,
    restaurantLastName: restaurantLastNameReducer,
    restaurantContactName: restaurantContactNameReducer,
    restaurantAddress: restaurantAddressReducer,
    restaurantEmail: restaurantEmailReducer,
    restaurantPhoneNumber: restaurantPhoneNumberReducer,
    orderItem: orderItemsReducer,
    deliveryFee: deliveryFeeReducer,
    tips: tipsReducer,
    totalOrderCost: totalOrderCostReducer,
    deliveryInstruction: deliveryInstructionReducer,
    expectedDeliveryDate: expectedDeliveryDateReducer,
    expectedDeliveryTime: expectedDeliveryTimeReducer,
    expectedPickupTime: expectedPickupTimeReducer,
    expectedPickUpDate: expectedPickUpDateReducer,
    deliveryAddressType: deliveryAddressTypeReducer,
    pickupAddressType: pickupAddressTypeReducer,
    orderSubmitState: orderSubmitStateReducer,
    itemType: itemTypeReducer,
    itemSize: itemSizeReducer,
    numberOfItems: numberOfItemsReducer,
    serviceType: serviceTypeReducer,
    clientSecret: clientSecretReducer,
    stripeDetails: stripeDetailsReducer,
    estimatedInfo: estimatedInfoReducer,
    pickupLocationInfo: pickUpLocationReducer,
    deliveryLocationInfo: deliveryLocationReducer,
    paymentMethod: paymentMethodReducer,
    additionalAddressInfo: additionalAddressInfoReducer,
    tax: taxReducer,
    customerAreaName: customerAreaNameReducer,
    customerBlockNumber: customerBlockNumberReducer,
    customerFlatNumber: customerFlatNumberReducer,
    customerRoadNumber: customerRoadNumberReducer,
    customerVillaNumber: customerVillaNumberReducer,
    customerTailNumber: customerTailNumberReducer,
    numberOfExtraItems: numberOfExtraItemsReducer,
    numberOfExtraItems2: numberOfExtraItems2Reducer,
    paidFormError: paidFormErrorReducer,
    pickupInstruction: pickupInstructionReducer,
});
